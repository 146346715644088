<template>
  <div class="project-group">
    <div class="headers">
      <el-input
        v-model="search"
        placeholder="输入内容查询"
        @input="searchHandle(search)"
      />
      <div class="btns">
        <el-button
          type="primary"
          @click="handleSubmit"
        >模型发布</el-button>
        <!-- <el-button
          type="primary"
          @click="getData"
        >刷新</el-button> -->
      </div>

    </div>
    <div class="content">
      <div class="left">
        <el-table
          v-loading="isLoading"
          :data="tableData"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          @filter-change="filterChange"
          @sort-change="sortChange"
        >
          <!-- <el-table-column type="selection" width="55" label="序号" /> -->
          <el-table-column
            label="发布名称"
            prop="name"
            show-overflow-tooltip
            sortable="custom"
          >
            <template #default="scope">
              <span style="color: #165DFF;cursor: pointer;" @click="goPushDetail(scope.row)">{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="模型名称"
            prop="modelName"
            show-overflow-tooltip
          />
          <el-table-column
            label="模型版本"
            prop="modelVersionName"
            sortable="custom"
            show-overflow-tooltip
          />
          <el-table-column
            label="镜像版本"
            prop="imageVersion"
            sortable="custom"
            show-overflow-tooltip
          >
            <template #default="{row}">
              {{ row.imageName + ':' + row.imageVersion }}
            </template>
          </el-table-column>
          <el-table-column
            label="发布状态"
            prop="status"
            column-key="status"
            :filters="[{ text: 'active', value: 'active' }, { text: 'pause', value: 'pause' }, { text: 'pending', value: 'pending' }, { text: 'exceptional', value: 'exceptional' }]"
            :filter-multiple="false"
          >
            <template #default="scope">
              <div
                v-if="
                  scope.row.status === 'Running' || scope.row.status === 'active'
                "
                class="state-circles"
                style="color: #165dff"
              >
                <div class="circles" style="background-color: #165dff" />
                <div>{{ scope.row.status }}</div>
              </div>
              <div
                v-else-if="
                  scope.row.status === 'pending' || scope.row.status === 'Pending'
                "
                class="state-circles"
                style="color: #fd8e3f"
              >
                <div class="circles" style="background-color: #fd8e3f" />
                <div>{{ scope.row.status }}</div>
              </div>
              <div
                v-else-if="
                  scope.row.status === 'stop' || scope.row.status === 'pause'
                "
                class="state-circles"
                style="color: #909399"
              >
                <div class="circles" style="background-color: #909399" />
                <div>{{ scope.row.status }}</div>
              </div>
              <div v-else class="state-circles" style="color: #ff1e1e">
                <div class="circles" style="background-color: #ff1e1e" />
                <div>{{ scope.row.status }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="副本数"
            prop="replicaCount"
            sortable="custom"
            show-overflow-tooltip
          />
          <el-table-column
            label="创建时间"
            prop="createTime"
            sortable="custom"
            show-overflow-tooltip
          >
            <template #default="scope">
              {{ formatDate(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column
            label="QPS"
            prop="queryPerSecond"
            sortable="custom"
            show-overflow-tooltip
          />
          <el-table-column label="操作">
            <template #default="scope">
              <div class="flex-style">
                <span
                  v-show="scope.row.status !== 'active'"
                  style="color: #165DFF;cursor: pointer; width: 80px"
                  @click="publishOrStopModel('1',scope.row.id)"
                >
                  发布
                </span>
                <span
                  v-show="scope.row.status === 'active'"
                  style="color: #165DFF;cursor: pointer; width: 80px"
                  @click="publishOrStopModel('0',scope.row.id)"
                >
                  停止
                </span>
                <span
                  style="color: #165DFF;cursor: pointer; width: 80px"
                  @click="editModelPublish(scope.row)"
                >
                  编辑
                </span>
                <span
                  style="color: #F02E3E; cursor: pointer; width: 80px"
                  @click="deleteModelPublish(scope.row.id)"
                >
                  删除
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="paginations">
          <div class="pagination">
            <div class="total">合计：{{ totalPage }}</div>
            <el-pagination
              v-model:currentPage="pageNum"
              :page-size="pageSize"
              :page-count="pagenumber"
              :page-sizes="pageSizes"
              layout="sizes"
              :total="totalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
            <div class="jumper">
              <div>{{ pageNum }}/{{ pagenumber }}</div>
              <div class="jumper-line" />
              <el-pagination
                v-model:currentPage="pageNum"
                :page-size="pageSize"
                :page-count="pagenumber"
                :page-sizes="pageSizes"
                background
                layout="jumper"
                :total="totalPage"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
            <el-pagination
              v-model:currentPage="pageNum"
              :page-size="pageSize"
              :page-count="pagenumber"
              :page-sizes="pageSizes"
              background
              layout="prev, next"
              :total="totalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <el-form ref="pushForm" :model="form" label-width="7em" :rules="rules">
          <el-form-item label="名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入名称"
            />
          </el-form-item>
          <el-form-item label="模型" prop="modelId">
            <el-input
              v-model="form.modelName"
              readonly
              style="cursor:pointer"
              placeholder="点击选择模型"
              @click="handleModelListOpen"
            />
          </el-form-item>
          <el-form-item label="发布版本" prop="modelVersionName">
            <el-select v-model="form.modelVersionName" placeholder="请选择发布版本" s>
              <el-option
                v-for="item in modelVersionOptions"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
          <el-row>
            <el-col :span="10">
              <el-form-item label="模型镜像" prop="imageRepository" class="is-required">
                <el-select
                  v-model="form.imageRepository"
                  collapse-tags
                  placeholder="请选择命名空间"
                  filterable
                  @change="handleSpaceChange"
                >
                  <el-option
                    v-for="item in spaceNameOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="" prop="imageName" label-width="0.5em">
                <el-select
                  v-model="form.imageName"
                  collapse-tags
                  placeholder="请选择模型镜像"
                  filterable
                  @change="handleImageChange"
                >
                  <el-option
                    v-for="item in imageOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="" prop="imageVersion" label-width="0.5em">
                <el-select
                  v-model="form.imageVersion"
                  collapse-tags
                  placeholder="请选择模型版本"
                  filterable
                >
                  <el-option
                    v-for="item in versionOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="带宽" prop="bandwidth">
            <el-select v-model="form.bandwidth" placeholder="请选择带宽">
              <el-option
                v-for="item in bandwidthOptions"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="规格" prop="specification">
            <el-select v-model="form.specification" placeholder="请选择规格">
              <el-option
                v-for="item in cpuOptions"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="副本数" prop="replicaCount">
            <el-input v-model="form.replicaCount" placeholder="请输入副本数" />
          </el-form-item>
          <el-form-item label="服务类型" prop="serviceType">
            <el-radio-group v-model="form.serviceType">
              <el-radio label="0">公共服务</el-radio>
              <el-radio label="1">私有服务</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="挂载容器路径" prop="containerMountPath" style="position: relative;">
            <template #label="{label}">
              <div class="tooltip-container">
                <el-tooltip
                  content="将选择的模型版本文件夹内容挂载到该指定容器路径下"
                  placement="top"
                >
                  <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
              </div>
              <span> {{ label }}</span>
            </template>
            <el-input v-model="form.containerMountPath" placeholder="请输入挂载路径" />
          </el-form-item>
          <div class="list-style">
            <el-form-item
              v-for="(item, index) in form.servicePortDTOList"
              :key="index"
              prop="servicePortDTOList"
              :label="index == 0 ? '容器端口' : ''"
              label-width="7em"
              :class="{ 'is-required': index == 0 }"
            >
              <el-input v-model="item.value" placeholder="容器端口" />
              <svg-icon v-show="form.servicePortDTOList.length>1" class="remove-item" icon-class="del-input" @click="removePort(item)" />
              <svg-icon icon-class="add-input" class="add-item" @click="addPort" />
            </el-form-item>
          </div>

          <el-form-item label="启动命令" class="is-required">
            <el-radio-group v-model="form.cmdType" @change="handleCmdTypeChange">
              <el-radio label="0">镜像默认</el-radio>
              <el-radio label="1">自定义</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-show="form.cmdType === '1'" class="list-style">
            <el-form-item
              v-for="(item, index) in form.cmdList"
              :key="index"
              label=""
              label-width="7em"
            >
              <el-input v-model="item.value" placeholder="" size="small" />
              <svg-icon v-show="form.cmdList.length>1" class="remove-item" icon-class="del-input" @click="removeCmd(item)" />
              <svg-icon icon-class="add-input" class="add-item" @click="addCmd" />
            </el-form-item>
          </div>
          <el-form-item label="启动参数" class="is-required">
            <el-radio-group v-model="form.argsType" @change="handleArgsTypeChange">
              <el-radio label="0">镜像默认</el-radio>
              <el-radio label="1">自定义</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-show="form.argsType === '1'" class="list-style">
            <el-form-item
              v-for="(item, index) in form.argsList"
              :key="index"
              label=""
              label-width="7em"
            >
              <el-input v-model="item.value" placeholder="" size="small" />
              <svg-icon v-show="form.argsList.length>1" class="remove-item" icon-class="del-input" @click="removeArg(item)" />
              <svg-icon icon-class="add-input" class="add-item" @click="addArg" />
            </el-form-item>
          </div>
          <el-row v-for="(item, index) in form.env" :key="index" class="env-item">
            <el-col :span="12">
              <el-form-item :label="index === 0 ? '环境变量' : ''" label-width="7em">
                <el-input v-model="item.key" placeholder="key" />
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="" label-width="0.5em">
                <el-input v-model="item.value" placeholder="value" />
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <svg-icon v-show="form.env.length>1" class="remove-item" icon-class="del-input" @click="removeEnv(item)" />
              <svg-icon icon-class="add-input" class="add-item" @click="addEnv" />
            </el-col>
          </el-row>
          <!--
          <el-form-item>
            <el-button type="primary" @click="handleSubmit">发布</el-button>
            <el-button @click="handleClose">取消</el-button>
          </el-form-item> -->
        </el-form>
      </div>
    </div>
    <!-- 修改副本数 -->
    <div class="model-publish-num">
      <el-dialog
        v-model="modelNumberVisible"
        title="修改副本数"
        :before-close="handleModelNumberClose"
        width="30%"
      >
        <el-form ref="form" :model="modelNumberForm" label-width="100px" :rules="rules">
          <el-form-item label="模型名称">
            {{ modelNumberForm.name }}
          </el-form-item>
          <el-form-item prop="replicaCount" label="副本数" label-width="7em">
            <el-input v-model="modelNumberForm.replicaCount" placeholder="请输入副本数" />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button @click="handleModelNumberClose">取 消</el-button>
          <el-button type="primary" @click="handleModelNumberSubmit">确 定</el-button>
        </template>
      </el-dialog>
    </div>
    <!-- 详情 -->
    <div class="dialog-container">
      <el-dialog
        v-model="modelDetailDialogVisible"
        :title="dialogTitle"
        :close-on-click-modal="false"
        :before-close="handleCloseDetailDialog"
        width="1000px"
      >
        <div class="dialog-content">
          <ModelPushDetailDialog :row="rowInfo" :dialog-visible="modelDetailDialogVisible" :projectid="projectId" />
        </div>
      </el-dialog>
    </div>
    <!-- 模型发布 -->
    <div class="model-list">
      <el-dialog
        v-model="modelListDialogVisible"
        title="模型列表"
        :before-close="handleModelListClose"
        width="1400px"
        :close-on-click-modal="false"
      >
        <!--  -->
        <ModelPushChooseModel ref="chooseModel" :visible="modelListDialogVisible" @changeModel="changeModel" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getModelPublishList,
  deleteModelPublish,
  publishModel,
  updateModelReplicas,
  getAllNameSpace,
  getAllImageByNamespace,
  getAllArtifactByNamespace,
  getModelVersion,
  publishModelVersion
} from '@/api/ai'
import moment from 'moment'
import { ElMessageBox, ElMessage } from 'element-plus'
import validator from '@/utils/baseValidate'
import ModelPushChooseModel from '@/components/ModelPushChooseModel.vue'
import ModelPushDetailDialog from '@/components/ModelPushDetailDialog.vue'
// 副本数校验
const checkReplicasTotal = (rule, value, callback) => {
  console.log(value, 'checkReplicasTotal')
  const reg = /^[1-9]\d*$/
  if (!value) {
    callback(new Error('请输入副本数'))
  } else {
    if (reg.test(value)) { // 验证通过也下一步
      return callback()
    } else {
      callback(new Error('请输入正确的副本数')) // 验证不通过下一步只是抛出个错误
    }
  }
}
export default {
  components: {
    ModelPushChooseModel,
    ModelPushDetailDialog

  },
  data() {
    return {
      isLoading: false,
      search: '',
      timer: null,
      pageSize: 10,
      pageNum: 1,
      orderBy: '',
      sortBy: '',
      status: '',
      totalPage: 0,
      pageSizes: [5, 10, 20, 30],
      tableData: [],
      multipleSelection: [],
      modelNumberVisible: false,
      modelNumberForm: {
        replicaCount: 1,
        name: '',
        id: ''
      },
      rules: {
        name: [
          { required: true, validator: validator.envName, trigger: 'blur' }
        ],

        modelId: [
          { required: true, message: '模型不能为空', trigger: 'change' }
        ],
        modelVersionName: [
          { required: true, message: '发布版本不能为空', trigger: 'change' }
        ],
        bandwidth: [
          { required: true, message: '带宽不能为空', trigger: 'change' }
        ],
        specification: [
          { required: true, message: '规格不能为空', trigger: 'change' }
        ],
        serviceType: [
          { required: true, message: '服务类型不能为空', trigger: 'blur' }
        ],
        replicaCount: [
          { required: true, validator: checkReplicasTotal, trigger: 'blur' }
        ]
      },
      rowInfo: {},
      modelPushDialogVisible: false,
      projectId: '',
      modelVersionOptions: [],
      currentModel: null,
      form: {
        name: '',
        modelId: '',
        modelName: '',
        modelVersionName: '',
        imageRepository: '',
        imageName: '',
        imageVersion: '',
        bandwidth: '',
        specification: '',
        replicaCount: 1,
        serviceType: '1',
        cmdType: '0',
        cmdList: [
          {
            value: ''
          }
        ],
        argsType: '0',
        argsList: [
          {
            value: ''
          }
        ],
        servicePortDTOList: [
          {
            value: ''
          }
        ],
        env: [
          {
            key: '',
            value: ''
          }
        ]
      },
      spaceNameOptions: [],
      imageOptions: [],
      versionOptions: [],
      bandwidthOptions: ['100M', '200M', '500M', '1000M'],
      cpuOptions: ['2核1G内存', '2核2G内存', '4核2G内存', '16核32G内存', '32核64G内存', '64核128G内存'],
      pagenumber: 0,
      modelListDialogVisible: false,
      modelDetailDialogVisible: false,
      dialogTitle: '',
      getDataTimer: null

    }
  },
  mounted() {
    getAllNameSpace().then((res) => {
      this.spaceNameOptions = res.data
    })
    this.getData()
    this.getDataTimer = setInterval(() => {
      getModelPublishList(
        this.pageNum,
        this.pageSize,
        this.sortBy,
        this.orderBy,
        this.search,
        this.status
      ).then((res) => {
        this.tableData = res.data.content
        this.totalPage = res.data.totalElements
        this.pagenumber = Math.ceil(res.data.totalElements / this.pageSize)
      })
    }, 10000)
    // 初始化挂载路径
    this.form.containerMountPath = '/home/' + this.$store.state.user.name
  },
  beforeUnmount() {
    clearInterval(this.getDataTimer)
  },
  methods: {
    formatDate(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 模糊查询
    searchHandle(val) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.pageNum = 1
        this.search = val
        this.$nextTick(() => {
          this.getData()
        })
      }, 500)
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getData()
    },
    sortChange(val) {
      this.sortBy = val.prop
      if (val.order === 'ascending') {
        this.orderBy = 'ASC'
      } else {
        this.orderBy = 'DESC'
      }
      this.getData()
    },
    filterChange(filtersObj) {
      const status = filtersObj.status
      if (status) {
        this.status = status.join(',')
      }
      this.pageNum = 1
      this.getData()
    },
    getData() {
      this.isLoading = true
      getModelPublishList(
        this.pageNum,
        this.pageSize,
        this.sortBy,
        this.orderBy,
        this.search,
        this.status
      ).then((res) => {
        this.isLoading = false
        this.tableData = res.data.content
        this.totalPage = res.data.totalElements
        this.pagenumber = Math.ceil(res.data.totalElements / this.pageSize)
      })
    },
    // 删除
    deleteModelPublish(id) {
      ElMessageBox.confirm('确认删除选择的模型吗？', '提示')
        .then(() => {
          deleteModelPublish(id).then((res) => {
            if (res.meta.status === 200) {
              ElMessage.success('删除成功')
              const totalPage = Math.ceil((this.totalPage - 1) / this.pageSize)
              this.pageNum = this.pageNum > totalPage ? totalPage : this.pageNum
              this.pageNum = this.pageNum < 1 ? 1 : this.pageNum
              this.getData()
            }
          })
        })
        .catch(() => {
          ElMessage.info('已取消删除')
        })
    },
    // 发布停止模型
    publishOrStopModel(status, id) {
      ElMessageBox.confirm(
        `确认${status === '1' ? '发布' : '停止'}选择的模型吗？`,
        '提示'
      )
        .then(() => {
          publishModel(id, status).then((res) => {
            ElMessage.success(`${status === '1' ? '发布' : '停止'}成功`)
            this.getData()
          })
        })
        .catch(() => {
          ElMessage.info('已取消操作')
        })
    },
    goPushDetail(row) {
      this.rowInfo = row
      this.dialogTitle = row.name
      this.modelDetailDialogVisible = true
    },
    /** 编辑副本数*/
    editModelPublish(row) {
      this.modelNumberVisible = true
      this.modelNumberForm = {
        replicaCount: row.replicaCount,
        id: row.id,
        name: row.name
      }
    },
    handleModelNumberClose() {
      this.modelNumberVisible = false
      this.$refs.form.resetFields()
    },
    // 提交
    handleModelNumberSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //  this.modelNumberForm.replicaCount去掉小数部分
          this.modelNumberForm.replicaCount = Math.floor(this.modelNumberForm.replicaCount)
          updateModelReplicas(this.modelNumberForm.id, this.modelNumberForm.replicaCount).then((res) => {
            ElMessage.success('修改副本数成功')
            this.modelNumberVisible = false
            this.getData()
          })
        } else {
          return false
        }
      })
    },
    /** 模型发布 */
    handleClose() {
      this.modelPushDialogVisible = false
      this.currentModel = null
      this.imageOptions = []
      this.versionOptions = []
      this.modelVersionOptions = []
      this.form = {
        name: '',
        modelId: '',
        modelName: '',
        modelVersionName: '',
        imageRepository: '',
        imageName: '',
        imageVersion: '',
        bandwidth: '',
        specification: '',
        replicaCount: 1,
        serviceType: '1',
        cmdType: '0',
        cmdList: [
          {
            value: ''
          }
        ],
        argsType: '0',
        argsList: [
          {
            value: ''
          }
        ],
        servicePortDTOList: [
          {
            value: ''
          }
        ],
        env: [
          {
            key: '',
            value: ''
          }
        ],
        containerMountPath: '/home/' + this.$store.state.user.name
      }
      setTimeout(() => {
        this.$refs.pushForm.clearValidate()
      })
      try {
        this.$refs.chooseModel.clearChoose()
      } catch (error) {
        console.log(error)
      }
    },
    // 模型发布
    handleSubmit() {
      const servicePortDTOList = this.form.servicePortDTOList.filter(item => {
        return item.value !== ''
      })
      if (servicePortDTOList.length === 0) {
        return ElMessage.warning('请输入容器端口')
      }
      // 判断servicePortDTOList的value是否都是数字
      const reg = /^[0-9]+$/
      if (servicePortDTOList.some(item => {
        return !reg.test(item.value)
      })) {
        return ElMessage.warning('容器端口只能输入数字')
      }
      if (this.form.imageRepository === '' || this.form.imageName === '' || this.form.imageVersion === '') {
        return ElMessage.warning('请选择模型镜像')
      }
      this.$refs.pushForm.validate((valid) => {
        if (valid) {
          // 8核32G 取出cpuLimit和ramLimit
          const [cpuLimit, ramLimit] = this.form.specification.match(/\d+/g)
          const env = {}
          this.form.env.forEach(item => {
            if (item.key !== '') {
              env[item.key] = item.value
            }
          })
          const cmdList = this.form.cmdList.filter(item => {
            return item.value !== ''
          }).map(i => {
            return i.value
          })
          const argsList = this.form.argsList.filter(item => {
            return item.value !== ''
          }).map(i => {
            return i.value
          })
          const data = {
            modelId: this.form.modelId,
            modelVersionName: this.form.modelVersionName,
            imageName: this.form.imageRepository + '/' + this.form.imageName,
            imageVersion: this.form.imageVersion,
            bandwidth: this.form.bandwidth,
            cpuLimit: Number(cpuLimit),
            ramLimit: Number(ramLimit),
            replicaCount: Number(this.form.replicaCount),
            serviceType: Number(this.form.serviceType),
            cmdList,
            argsList,
            env,
            servicePortDTOList: servicePortDTOList.map(i => {
              return {
                targetPort: i.value,
                nodePort: ''
              }
            }),
            containerMountPath: this.form.containerMountPath,
            name: this.form.name
          }
          publishModelVersion(data).then((res) => {
            if (res.meta.status === 200) {
              ElMessage.success('发布成功')
              this.handleClose()
              this.getData()
            }
          })
        }
      })
    },
    handleSpaceChange(val) {
      this.imageOptions = []
      this.versionOptions = []
      this.form.imageName = ''
      this.form.imageVersion = ''
      if (val) {
        // 查镜像
        this.addFormLoading = true
        getAllImageByNamespace(val).then((res) => {
          this.imageOptions = res.data
          this.addFormLoading = false
        })
      } else {
        this.imageOptions = []
      }
    },
    handleImageChange(val) {
      this.versionOptions = []
      this.form.imageVersion = ''
      if (val) {
        // 查版本
        this.addFormLoading = true
        getAllArtifactByNamespace(this.form.imageRepository, val).then(
          (res) => {
            this.versionOptions = res.data
            this.addFormLoading = false
          }
        )
      } else {
        this.versionOptions = []
      }
    },
    // 打开选择模型
    handleModelListOpen() {
      this.modelListDialogVisible = true
    },
    // 关闭选择模型
    handleModelListClose() {
      this.modelListDialogVisible = false
      this.$nextTick(() => {
        if (!this.currentModel) return
        getModelVersion(this.form.modelId).then((res) => {
          this.modelVersionOptions = res.data
        })
        if (this.currentModel.imageRepository) {
          this.form.imageRepository = this.currentModel.imageRepository
        }
        if (this.currentModel.imageName) {
          this.form.imageName = this.currentModel.imageName
        }
        if (this.currentModel.imageVersion) {
          this.form.imageVersion = this.currentModel.imageVersion
        }
      })
    },
    // 模型变化
    changeModel(item) {
      if (!item.id) return
      this.form.modelVersionName = ''
      this.versionOptions = []
      // 查镜像
      if (item.imageRepository) {
        this.handleSpaceChange(item.imageRepository)
        // 查镜像版本
        if (item.imageName) {
          getAllArtifactByNamespace(item.imageRepository, item.imageName).then(
            (res) => {
              this.versionOptions = res.data
              this.addFormLoading = false
            }
          )
        }
      }
      this.form.imageName = item.imageName || ''
      this.form.imageVersion = item.imageVersion || ''
      this.form.imageRepository = item.imageRepository || ''
      this.form.modelId = item.id
      this.form.modelName = item.name
      this.currentModel = item
    },
    // 启动脚本
    addArg() {
      this.form.argsList.push({
        value: ''
      })
    },
    removeArg(item) {
      var index = this.form.argsList.indexOf(item)
      if (index !== -1) {
        this.form.argsList.splice(index, 1)
      }
    },
    // 启动命令
    addCmd() {
      this.form.cmdList.push({
        value: ''
      })
    },
    removeCmd(item) {
      var index = this.form.cmdList.indexOf(item)
      if (index !== -1) {
        this.form.cmdList.splice(index, 1)
      }
    },
    // 容器端口
    addPort() {
      this.form.servicePortDTOList.push({
        value: ''
      })
    },
    removePort(item) {
      var index = this.form.servicePortDTOList.indexOf(item)
      if (index !== -1) {
        this.form.servicePortDTOList.splice(index, 1)
      }
    },
    // 环境变量
    addEnv() {
      this.form.env.push({
        key: '',
        value: ''
      })
    },
    removeEnv(item) {
      var index = this.form.env.indexOf(item)
      if (index !== -1) {
        this.form.env.splice(index, 1)
      }
    },
    handleCmdTypeChange(val) {
      this.form.cmdList = [
        {
          value: ''
        }
      ]
    },
    handleArgsTypeChange(val) {
      this.form.argsList = [
        {
          value: ''
        }
      ]
    },
    /** 详情 */
    handleCloseDetailDialog() {
      this.modelDetailDialogVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.project-group {
  background-color: #fff;
  min-height: calc(100% - 56px);
  padding: 16px 20px;
  border-radius: 4px;
  height: 100%;
  :deep(.el-table__cell) {
      .el-icon {
        font-size: 16px;
      }
  }
  :deep(.el-dialog) {
    max-height: 1000px;
  }
  :deep(.el-dialog__body) {
    max-height: 800px;
    overflow-y: auto;
  }
  :deep(.el-radio__original) {
    display: none !important;
    /* 隐藏原生 radio 输入，但仍然允许交互 */
  }
  :deep(.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner) {
    box-shadow: none !important;
  }
  .list-style {
    .el-input{
      width: 90%;
    }
    .svg-icon{
      margin-left: 8px;
    }
  }
  .env-item{
    .svg-icon{
      margin-left: 8px;
      // line-height: 40px;
      transform: translateY(4px);
    }
  }
  .headers {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .el-button {
      margin-right: 8px;
    }
    :deep(.el-button--default) {
      background-color: #f2f3f5;
      color: #4e5969;
      border: none;
    }
    :deep(.el-button--primary){
      color: #fff;
    }
    :deep(.el-input) {
      width: 260px;
      .el-input__inner {
        height: 36px;
        background-color: #f2f3f5;
      }
    }
    .del-btn {
      background: #f2f3f5;
      color: #4e5969;
      &:hover {
        background-color: #f2f3f5 !important;
      }
    }
  }
  .model-publish-num{
    :deep(.el-button--primary){
      color: #fff !important;
    }
  }

  .paginations{
    margin-top: 16px;
    float: right;
  }
  .content{
    display: flex;
    gap:16px;
    height: calc(100% - 56px);
    .left{
      flex:2;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #CFD3E3;
      padding: 12px 8px;
      min-width: 980px;
    }
    .right{
      flex:1;
      min-width: 581px;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #CFD3E3;
      background-color: #f5f7fa;
      padding: 12px 22px;
      height: 100%;
      overflow: auto;
      .tooltip-container{
        position: absolute;
        left: -20px;
        top: 2px;
      }
      .el-select{
        width: 100%;
      }
      :deep(.el-button--default){
        border: none;
        background-color: #F2F3F5;
        color: #4e5969;
      }
      :deep(.el-button--primary){
        color: #fff !important;
      }
    }
  }
}
</style>
